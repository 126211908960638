import { render, staticRenderFns } from "./CreateEducation.vue?vue&type=template&id=4328ed37&scoped=true&"
import script from "./CreateEducation.vue?vue&type=script&lang=js&"
export * from "./CreateEducation.vue?vue&type=script&lang=js&"
import style0 from "./CreateEducation.vue?vue&type=style&index=0&id=4328ed37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4328ed37",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VAutocomplete,VCard,VCheckbox,VCol,VForm,VRow,VSkeletonLoader})
