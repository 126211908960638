<template>
  <v-card class='mb-5 form-container py-5' :loading="loading" elevation="0" color="transparent">
    <h2 class='create-header mb-5 text-center'>🎓 College Details</h2>
    <v-form ref='educationForm' v-model='valid' lazy-validation @submit.prevent='validate()' id='create-education-form' class='v-text-field--rounded form' loading v-if="currentUser.profile">
      <v-row>
        <v-col v-if="$apollo.queries.colleges.loading">
          <v-card>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col v-else>
          <v-autocomplete
            :disabled="otherCollege"
            ref="college"
            auto-select-first
            v-model="currentUser.profile.college[0]"
            :items="colleges"
            :loading="loading"
            outlined
            :rules="collegeRules"
            hide-no-data
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="College / University"
            placeholder="Start typing to fetch your college"
            prepend-inner-icon="mdi-school"
            :menu-props="autocompleteMenuProps"
          ></v-autocomplete>
          <v-checkbox
            class="mt-0"
            v-model="otherCollege"
            label="My College / University is not listed."
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="$apollo.queries.skills.loading">
          <v-card>
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col v-else>
          <v-autocomplete
            ref="skills"
            v-model="currentUser.profile.skill"
            :items="skills"
            :search-input.sync="searchInput"
            @input="searchInput = null"
            :loading="loading"
            :rules="skillsRules"
            :counter="50"
            outlined
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            persistent-hint
            auto-select-first
            small-chips
            hint="⚡ Add relevant skills so that people can reach you faster!"
            label="Skills"
            :menu-props="autocompleteMenuProps"
            placeholder="Start typing to fetch skills"
            prepend-inner-icon="mdi-flask"
            multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
      <actions :loading="loading" back="profileDetails" :valid="valid"/>
      <v-alert text outlined color='error' icon='mdi-fire' v-if='error' class='mt-5'>
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import Actions from '../../../components/Onboarding/Actions'
import autocomplete from '../../../mixins/autocomplete'
const COLLEGES = require('../../../graphql/queries/colleges.graphql')
const SKILLS = require('../../../graphql/queries/skills.graphql')
const UPDATE_USER = require('../../../graphql/updateUser.graphql')
export default {
  apollo: {
    colleges: {
      query: COLLEGES
    },
    skills: {
      query: SKILLS
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    valid: true,
    loading: false,
    searchInput: null,
    collegeRules: [v => (!!v) || 'Field is required'],
    skillsRules: [
      v => (v.length > 0) || 'Field is required',
      v => v.length < 51 || 'Cannot select more than 50 skills'
    ],
    error: null,
    otherCollege: false
  }),
  methods: {
    validate () {
      if (this.$refs.educationForm.validate()) {
        this.submitEducationDetails()
      }
    },
    async submitEducationDetails () {
      const college = typeof this.currentUser.profile.college[0] === 'string' ? this.currentUser.profile.college[0] : this.currentUser.profile.college[0].id
      const skills = typeof this.currentUser.profile.skill[0] === 'string' ? this.currentUser.profile.skill : this.currentUser.profile.skill.map(skill => skill.id)
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              college: college,
              skills: skills
            }
          }
        })
        await this.$router.push({ name: 'selectCategories' })
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    Actions
  },
  watch: {
    otherCollege (newValue) {
      if (newValue) {
        this.currentUser.profile.college[0] = '2688'
      } else {
        this.currentUser.profile.college = []
      }
    }
  },
  mixins: [autocomplete]
}
</script>
<style lang='scss' scoped>
  .form-container {
    border-radius: 4px;
    .form {
      padding: 0 1rem 0;
    }
  }
  .access-header {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
  }
</style>
